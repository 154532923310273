import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useCookies } from 'react-cookie';
import { Carousel } from 'react-responsive-carousel';
import { graphql, Link } from 'gatsby';

import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import ArticleCarousel from 'components/ArticleCarousel';

import { filterByTagId } from '../../utils/functions';

import './HomePage.scss';

const HomePage: FC<HomepageTypes.HomepageProps> = ({
  data: {
    homepage: { urls, seo, banners, bannersMobile },
    allArticleList: { nodes: categories },
    allArticle: { nodes: articles },
    faq,
    global: { legalImage, legalDescription, legalButton },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;
  const faqs = faq.items.slice(0, 6);

  const [cookies, setCookie] = useCookies(['legals']);
  const [show, setShow] = React.useState(cookies.legals !== 'checked');

  const hideLegals = () => {
    setShow(false);
    setCookie('legals', 'checked', { path: '/' });
  };

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div id="home">
        <Carousel
          showThumbs={false}
          showIndicators
          showArrows
          swipeable
          showStatus={false}
          infiniteLoop
          className="d-none d-md-block"
        >
          {banners.map(({ image, link }) => (
            <a href={link.url} target="_blank" rel="noreferrer">
              <Image imageData={image} alt={link.name} className="w-100" />
            </a>
          ))}
        </Carousel>
        <Carousel
          showThumbs={false}
          showIndicators
          showArrows
          swipeable
          showStatus={false}
          infiniteLoop
          className="d-block d-md-none"
        >
          {bannersMobile.map(({ image, link }) => (
            <a href={link.url} aria-label={link.name} target="_blank" rel="noreferrer">
              <Image imageData={image} alt={link.name} className="w-100" />
            </a>
          ))}
        </Carousel>
        <div className="container section-container">
          {categories.map(({ title, tags }) => (
            <div className="home__article-list">
              <div className="row">
                <div className="col mb-4">
                  <h2 className="text-uppercase pt-5">
                    <i>{title}</i>
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ArticleCarousel data={filterByTagId(articles, tags.id).slice(0, 9)} />
                </div>
              </div>
            </div>
          ))}
          <div className="row">
            <div className="col mb-4">
              <h2 className="text-uppercase pt-5">
                <i>{faq.name}</i>
              </h2>
              <hr />
            </div>
          </div>
          <ul className="row list-unstyled home__faqs">
            {faqs.map(({ title }) => (
              <li className="col-12 col-md-6 col-lg-4 py-2">
                <h5 className="ps-4">
                  <Link to={faq.url} aria-label={title}>
                    {title}
                  </Link>
                </h5>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Modal.Dialog className="my-0 modal-legals">
          <Modal.Body className="border-0 text-center">
            <Image imageData={legalImage} alt={legalDescription} />
            <p className="py-4 mb-0">{legalDescription}</p>
            <button type="button" className="p-2 pb-1" onClick={hideLegals}>
              {legalButton}
            </button>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    homepage(lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      title
      banners {
        link {
          name
          url
        }
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
            }
          }
        }
      }
      bannersMobile {
        link {
          name
          url
        }
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800, quality: 100)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800, quality: 100)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800, quality: 100)
            }
          }
        }
      }
    }
    allArticleList {
      nodes {
        title
        url
        tags {
          id
          name
        }
      }
    }
    allArticle {
      nodes {
        id
        url
        name
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800, quality: 100)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800, quality: 100)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800, quality: 100)
            }
          }
        }
        tags {
          id
          name
        }
      }
    }
    faq {
      name
      items {
        title
      }
      url
    }
    global {
      legalDescription
      legalButton
      legalImage {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 200, quality: 100)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 200, quality: 100)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 200, quality: 100)
          }
        }
      }
    }
  }
`;

export default HomePage;
