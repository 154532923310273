import React, { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { arrayChunk } from '../../utils/functions';
import { ArticleCarouselProps } from './models';

import './ArticleCarousel.scss';

const ArticleCarousel: FC<ArticleCarouselProps> = ({ data: items }) => (
  <div className="article-carousel">
    <Carousel
      swipeable
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
      className="d-none d-md-block"
    >
      {arrayChunk(items, 3).map((cols) => (
        <div className="container-fluid px-0" key={cols[0].url}>
          <div className="row mx-0">
            {cols.map(({ id, name, image, url }) => (
              <div
                data-testid="article-carousel-item"
                className="article-carouse-item col"
                key={id}
              >
                <div className="article-carousel-item-container px-4 px-md-0">
                  <div className="article-carousel-item-image mx-auto">
                    <Link to={url} aria-label={name}>
                      <Image imageData={image} alt={name} />
                    </Link>
                  </div>
                  <strong className="mt-3 article-carousel-item-title d-block">
                    <Link to={url} aria-label={name}>
                      {name}
                    </Link>
                  </strong>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </Carousel>
    <Carousel
      swipeable
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
      className="d-block d-md-none"
    >
      {items.map(({ id, name, image, url }) => (
        <div data-testid="article-carousel-item" className="article-carousel-item mx-2" key={id}>
          <div className="article-carousel-item-container px-4 px-md-0">
            <div className="article-carousel-item-image mx-auto">
              <Link to={url} aria-label={name}>
                <Image imageData={image} alt={name} />
              </Link>
            </div>
            <strong className="mt-3 d-block">
              <Link to={url} aria-label={name}>
                {name}
              </Link>
            </strong>
          </div>
        </div>
      ))}
    </Carousel>
  </div>
);

export default ArticleCarousel;
